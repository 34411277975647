import React, { useState } from 'react';
import { CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../actions/userActions';

const ExportUsers = () => {



    const { userInfo } = useSelector((state) => state.userLogin);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()



    const handleDownload = async () => {
        setLoading(true)
        try {
            // Make API call to download the file
            const response = await axios.get('/api/users/export-users', {
                responseType: 'blob', // Important for downloading files
                headers: {
                    Authorization: `Bearer ${userInfo.token}`, // Include token if required
                },
            });

            // Create a link to download the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'registered-users.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false)
            toast('Users Exported Successfully', {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
        } catch (error) {
            setLoading(false)
            console.log(error.response);
            if (error.response.request.statusText === "Unauthorized") {

                toast('Login Expired', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                })

                setTimeout(() => {
                    dispatch(logout())
                    navigate("/login")
                }, 3000)


            } else {
                toast('Failed to Export Users', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                })
            }

            ;
        }
    };

    return (
        loading ? <Typography textAlign="center">
            <CircularProgress />
        </Typography> :
            <Tooltip title="Export Users">
                <IconButton
                    variant="contained"
                    className="button-secondary"
                    onClick={handleDownload}


                >
                    <FileDownloadIcon />
                </IconButton>
            </Tooltip>
    );
};

export default ExportUsers;
